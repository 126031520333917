<template>
  <div
    class="flex items-center justify-center full-width bg-grey-10"
    style="height: 100vh"
  >
    <div class="row">
      <div class="column q-pt-xs">
        <q-icon size="md" name="mdi-cancel" class="q-mr-md" color="negative" />
      </div>
      <div class="column">
        <h4 class="flex items-center q-ma-none text-white">Obehörig åtkomst</h4>
        <p class="q-pt-md text-grey-6">
          Du har inte behörighet till denna sidan
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AccessDenied',

  setup() {
    return {}
  },
})
</script>
